'use client';

import React, { ReactNode } from 'react';
import { BaseDrawer } from '#/components/drawer/base-drawer';

interface AlertDrawerProps {
  title: string;
  open: boolean;
  text?: string;
  onConfirm: () => void;
  onClose?: () => void;
  children?: ReactNode;
  hasCancel?: boolean;
  submitText?: string;
  cancelText?: string;
  isLoading?: boolean;
  trigger?: ReactNode;
}

export default function AlertDrawer({
  title,
  open,
  onConfirm,
  onClose,
  text,
  children,
  submitText,
  cancelText,
  isLoading,
  hasCancel = true,
  trigger
}: AlertDrawerProps) {
  return (
    <BaseDrawer
      title={title}
      open={open}
      onSubmit={onConfirm}
      onClose={onClose}
      submitText={submitText}
      cancelText={cancelText}
      hasCancel={hasCancel}
      isLoading={isLoading}
      trigger={trigger}
    >
      {text && <p>{text}</p>}
      {children}
    </BaseDrawer>
  );
}
