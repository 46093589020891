'use client';

import { WebViewEvent } from '#/lib/webview';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import { AppPushItem, AppPushType } from '#/data/notification';
import toast from 'react-hot-toast';
import Button from '../button/button';

function AppPushSection() {
  const router = useRouter();
  const getPushItemFromApp = useCallback((e: any) => {
    const item = (e.detail as AppPushItem[]).at(0);
    if (item) {
      if (item.data.appStatus === 'foreground') {
        switch (item.data.type) {
          case AppPushType.ANALYSIS:
            toast.custom((t) => {
              setTimeout(() => {
                toast.remove(t.id);
              }, 5000);
              return (
                <div
                  className={`${
                    t.visible ? 'animate-enter' : 'animate-leave'
                  } max-w-md w-full bg-background shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                  <div className="p-4 flex flex-col gap-1">
                    {/* {item.title && (
                      <h2 className=" text-lg font-semibold">{item.title}</h2>
                    )} */}
                    <p>{item.body}</p>
                    <div className="flex gap-2 ">
                      <Button
                        onClick={() => {
                          toast.remove(t.id);
                          router.push(item.data.url);
                        }}
                        size="sm"
                      >
                        이동
                      </Button>
                      <Button onClick={() => toast.remove(t.id)} size="sm">
                        닫기
                      </Button>
                    </div>
                  </div>
                </div>
              );
            });
            break;

          default:
            break;
        }
      } else {
        switch (item.data.type) {
          case AppPushType.ANALYSIS:
            router.push(item.data.url);
            break;

          default:
            break;
        }
      }
      router.refresh();
    }
  }, []);
  AppPushType;

  useEffect(() => {
    window.addEventListener(WebViewEvent.pushFromApp, getPushItemFromApp);

    return () => {
      window.removeEventListener(WebViewEvent.pushFromApp, getPushItemFromApp);
    };
  }, []);

  return <></>;
}

export default AppPushSection;
