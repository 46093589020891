import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '#/components/ui/drawer';
import { ReactNode } from 'react';
import Button from '../button/button';
import { Icons } from '../icons';

interface BaseDrawerProps {
  title?: string;
  description?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: ReactNode;
  onSubmit?: () => void;
  onClose?: () => void;
  submitBtnDisabled?: boolean;
  submitText?: string;
  cancelText?: string;
  hasCancel?: boolean;
  isLoading?: boolean;
  trigger?: ReactNode;
  footerChildren?: ReactNode;
}

export function BaseDrawer({
  title,
  description,
  open,
  onOpenChange,
  children,
  onSubmit,
  onClose,
  submitBtnDisabled,
  isLoading,
  submitText = '확인',
  cancelText = '취소',
  hasCancel = true,
  trigger,
  footerChildren
}: BaseDrawerProps) {
  return (
    <Drawer
      open={open}
      onOpenChange={(isOpen) => {
        if (onOpenChange) {
          onOpenChange(isOpen);
        } else {
          !isOpen && onClose && onClose();
        }
      }}
    >
      <DrawerTrigger>{trigger}</DrawerTrigger>
      <DrawerContent>
        <div className="relative">
          <div className="mx-auto w-full max-w-sm ">
            <DrawerHeader>
              <DrawerTitle className="text-center flex justify-center items-center">
                <p className="w-full">{title}</p>
                {!hasCancel && (
                  <DrawerClose asChild>
                    <div
                      onClick={onClose}
                      className=" absolute right-4 float-right"
                    >
                      <Icons.x />
                    </div>
                  </DrawerClose>
                )}
              </DrawerTitle>
              <DrawerDescription>{description}</DrawerDescription>
            </DrawerHeader>
            <div className="px-4">{children}</div>
            <DrawerFooter className={`${footerChildren && 'border-t-1 mt-4'}`}>
              {footerChildren}
              {!!onSubmit && (
                <Button
                  onClick={onSubmit}
                  isDisabled={submitBtnDisabled}
                  isLoading={isLoading}
                >
                  {submitText}
                </Button>
              )}
              {hasCancel && (
                <DrawerClose asChild>
                  <Button variant="faded" onClick={onClose}>
                    {cancelText}
                  </Button>
                </DrawerClose>
              )}
            </DrawerFooter>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
