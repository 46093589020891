interface AppPushData {
  type: AppPushType;
  url: string;
  appStatus?: AppPushStatus;
}

type AppPushStatus = 'foreground' | 'background';

export interface AppPushItem {
  title?: string;
  body: string;
  data: AppPushData;
}

export enum AppPushType {
  ANALYSIS = 'ANALYSIS'
}
