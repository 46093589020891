'use client';

import { isIOS } from '#/lib/webview';
import { useEffect, useRef } from 'react';

export interface ContentsWrapperProps {
  children: React.ReactNode;
}

export default function ContentsWrapper({ children }: ContentsWrapperProps) {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleVisualViewPortResize = () => {
      const currentVisualViewport = Number(window.visualViewport?.height);
      if (divRef) {
        if (document.documentElement.clientHeight === currentVisualViewport) {
          divRef.current!.style.height = '';
        } else {
          divRef.current!.style.height = `${document.documentElement.clientHeight - currentVisualViewport + (divRef.current?.clientHeight ?? 0)}px`;
        }
      }
    };
    if (isIOS()) {
      if (window.visualViewport) {
        window.visualViewport.addEventListener(
          'resize',
          handleVisualViewPortResize
        );
      }
      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener(
            'resize',
            handleVisualViewPortResize
          );
        }
      };
    }
  }, []);
  return (
    <div ref={divRef} className="bg-background ">
      {children}
    </div>
  );
}
