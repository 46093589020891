'use client';

import { useAlertDrawer } from '#/hooks/use-alert-drawer';
import AlertDrawer from './alert-drawer';

export default function GlobalAlertDrawer() {
  const { type, title, contents, isOpen, onConfirm, closeDrawer, loading } =
    useAlertDrawer();
  return (
    <AlertDrawer
      title={title}
      open={isOpen}
      onConfirm={onConfirm}
      onClose={closeDrawer}
      isLoading={loading}
      hasCancel={type !== 'alert'}
    >
      {contents}
    </AlertDrawer>
  );
}
