import { ReactNode } from 'react';
import { create } from 'zustand';

type AlertType = 'alert' | 'confirm';

interface useAlertDrawerStore {
  isOpen: boolean;
  title: string;
  type: AlertType;
  loading: boolean;
  contents?: string | ReactNode;
  open: (params: {
    type: AlertType;
    title: string;
    contents?: string | ReactNode;
    onConfirm?: () => Promise<void>;
  }) => void;
  onConfirm: () => void;
  closeDrawer: () => void;
  callback: () => Promise<void>;
  openConfirm: (params: {
    title: string;
    contents?: string | ReactNode;
    onConfirm: () => Promise<void>;
  }) => void;
  openAlert: (params: {
    title: string;
    contents?: string | ReactNode;
    onConfirm: () => Promise<void>;
  }) => void;
}

export const useAlertDrawer = create<useAlertDrawerStore>((set, get) => ({
  isOpen: false,
  open: ({ title, type, contents, onConfirm }) => {
    set({
      title,
      type,
      contents,
      isOpen: true,
      callback: onConfirm
    });
  },
  closeDrawer: () => set({ isOpen: false, contents: '' }, false),
  title: '',
  type: 'alert',
  contents: '',
  loading: false,
  onConfirm: async () => {
    const cb = get().callback;
    if (cb) {
      set({ loading: true });
      try {
        await cb();
      } catch (error) {
        console.log(error);
      } finally {
        set({ loading: false });
      }
    } else {
      set({ isOpen: false });
    }
  },
  callback: () => Promise.resolve(),
  openConfirm: ({ title, contents, onConfirm }) => {
    const { open } = get();
    open({
      title,
      contents,
      type: 'confirm',
      onConfirm
    });
  },
  openAlert: ({ title, contents, onConfirm }) => {
    const { open } = get();
    open({
      title,
      contents,
      type: 'alert',
      onConfirm
    });
  }
}));
